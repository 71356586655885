import {
    Card,
    CardContent,
    CardHeader,
    MenuItem,
    Select,
    Stack,
    Step,
    StepButton,
    Stepper,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Questionnaire } from "../../globals/types";
import useSectionIndex from "../../hooks/useSectionIndex";

import { NumberIcon } from "./NumberIcon";

type Props = {
    questionnaire: Questionnaire;
};

const SectionsNav: React.FC<Props> = ({ questionnaire }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sectionIndex, setSectionIndex] = useSectionIndex();
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const smallScreen = windowWidth < 900;

    const stepperNavigation = () => (
        <Stepper activeStep={sectionIndex} orientation="vertical">
            {questionnaire.sections.map((section, i) => (
                <Step
                    key={section.id}
                    completed={false}
                    active={i === sectionIndex}
                    sx={(theme) => ({
                        "& .MuiStepLabel-root .MuiStepIcon-root": {
                            color: theme.palette.primary.container, // circle color (NOT ACTIVE)
                        },
                        "& .MuiStepLabel-root .MuiStepIcon-text": {
                            fill: "#1D212B", // circle's number (NOT ACTIVE)
                        },
                        "& .MuiStepLabel-label": {
                            color: "#000000", // Just text label (NOT ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-active": {
                            color: theme.palette.primary.main, // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                            fill: theme.palette.primary.contrastText, // circle's number (ACTIVE)
                        },
                    })}
                >
                    <StepButton disabled={false} disableRipple onClick={() => setSectionIndex(i)}>
                        {section.name}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    );

    const selectNavigation = () => (
        <Select
            fullWidth
            value={sectionIndex}
            sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
            onChange={(e) => {
                const newValue = typeof e.target.value === "string" ? parseInt(e.target.value, 10) : e.target.value;
                setSectionIndex(newValue);
            }}
            renderValue={(value) => {
                const section = questionnaire.sections[value];
                return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {NumberIcon(value + 1)}
                        <Typography variant="body2" color="primary">
                            {section.name}
                        </Typography>
                    </Stack>
                );
            }}
        >
            {questionnaire.sections.map((section, i) => (
                <MenuItem key={section.id} value={i}>
                    <Typography variant="body2">{section.name}</Typography>
                </MenuItem>
            ))}
        </Select>
    );

    const sectionProgress = `${sectionIndex + 1}/${questionnaire.sections.length}`;

    return (
        <Card sx={{ border: "solid 0.5px #D5E3FE", borderRadius: "12px", padding: 2 }}>
            <CardHeader
                title={
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">
                            <FormattedMessage defaultMessage="Sections" />
                        </Typography>
                        {smallScreen && <Typography variant="body2">{sectionProgress}</Typography>}
                    </Stack>
                }
            />
            <CardContent>{smallScreen ? selectNavigation() : stepperNavigation()}</CardContent>
        </Card>
    );
};

export default SectionsNav;
