import { Box, Typography } from "@mui/material";
import React from "react";

export const NumberIcon = (number: number) => {
    return (
        <Box
            width="29px"
            height="29px"
            justifyContent="center"
            alignItems="center"
            sx={{
                display: "flex",
                border: "1px solid",
                borderColor: (theme) => theme.palette.primary.container,
                borderRadius: "50%",
            }}
        >
            <Box
                width="23px"
                height="23px"
                alignItems="center"
                justifyContent="center"
                sx={{ display: "flex", borderRadius: "50%", backgroundColor: (theme) => theme.palette.primary.main }}
            >
                <Typography variant="body2" color="white">
                    {number}
                </Typography>
            </Box>
        </Box>
    );
};
