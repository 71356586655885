import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useIntl } from "react-intl";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

const BooleanQuestion: React.FC<QuestionProps> = ({ name, saveDraft, "data-testid": dataTestId, ...props }) => {
    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[props.question.id];
    const { formatMessage } = useIntl();

    const { touched } = getFieldMeta(`${props.question.id}.value`);
    return (
        <FormControl sx={{ width: "100%" }} error={touched && Boolean(error)}>
            <RadioGroup
                row
                name={name}
                value={values[props.question.id].value}
                aria-labelledby={props["aria-labelledby"]}
                onChange={(event) => {
                    setFieldValue(props.question.id, {
                        value: event.target.value,
                        fileNames: values[props.question.id].fileNames,
                    });
                    saveDraft([event.target.value]);
                }}
            >
                <FormControlLabel
                    value="1"
                    control={<Radio disabled={props.disabled} />}
                    label={formatMessage({ defaultMessage: "Yes" })}
                />
                <FormControlLabel
                    value="0"
                    control={<Radio disabled={props.disabled} />}
                    label={formatMessage({ defaultMessage: "No" })}
                />
            </RadioGroup>
            <FormHelperText>
                <Stack direction="row" justifyContent="flex-start">
                    <Typography>{touched && error ? error.value : ""}</Typography>
                </Stack>
            </FormHelperText>
        </FormControl>
    );
};

export default BooleanQuestion;
