import { useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import React from "react";

import { DeleteAssessmentFileDocument } from "../../../generated/graphql";
import { FileListItem } from "../FileListItem";

export interface FilesListProps {
    fileNames: string[];
    onFileRemove: (fileName: string) => void;
    disabled?: boolean;
    answerId: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilesList: React.FC<FilesListProps> = ({ fileNames, onFileRemove, disabled, answerId, setLoading }) => {
    const [deleteFile] = useMutation(DeleteAssessmentFileDocument);
    const handleFileRemove = (fileName: string) => {
        setLoading(true);
        deleteFile({
            variables: {
                input: {
                    referenceId: answerId,
                    fileName: fileName,
                },
            },
            onCompleted: () => {
                onFileRemove(fileName);
            },
        });
        setLoading(false);
    };

    return (
        <Grid>
            {fileNames?.map((fileName) => (
                <FileListItem
                    key={fileName}
                    fileName={fileName}
                    answerId={answerId}
                    onDeleteFile={handleFileRemove}
                    disabled={disabled}
                />
            ))}
        </Grid>
    );
};
