import { useMutation } from "@apollo/client";
import { Grid, LinearProgress, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { QuestionProps } from "../../components/Question/interfaces";
import { CreateAssessmentUploadUrlDocument, FileUpload } from "../../generated/graphql";
import { Answer } from "../../globals/types";
import { initialValue } from "../Form/helpers";

import DropZone from "./DropZone";
import { FilesList } from "./FilesList";
import { UploadInput, UploadWrapper } from "./styled";

type Props = {
    answer?: Answer;
    saveDraft: QuestionProps["saveDraft"];
    questionId: string;
    uploadType: FileUpload;
    disabled?: boolean;
    setSubmitBlocked: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAX_FILE_SIZE = 20 * 1024 * 1024;

export const Files: React.FC<Props> = ({ answer, saveDraft, questionId, uploadType, disabled, setSubmitBlocked }) => {
    const { getFieldMeta, setFieldError, setFieldTouched } = useFormikContext<Record<string, initialValue>>();
    const { touched, error } = getFieldMeta(`${questionId}.fileNames`);
    const { formatMessage } = useIntl();
    const { id: assessmentId } = useParams<{ id: string }>();
    const [createUploadUrl] = useMutation(CreateAssessmentUploadUrlDocument);
    const [loading, setLoading] = React.useState(false);

    const handleFileChange = async (pendingFiles: FileList | null) => {
        if (!pendingFiles) return;

        setFieldTouched(`${questionId}.fileNames`, true, false);

        // need an answer id as reference to upload files, so have to save a draft if it doesn't exist
        let answerId = answer?.id;
        if (!answerId) {
            answerId = await saveDraft(undefined, undefined);
        }

        const blockedFiles: string[] = [];
        const pendingFilesInfo = Array.from(pendingFiles).reduce(
            (acc: { name: string; file: File }[], selectedFile) => {
                const fileName = selectedFile.name;
                if (selectedFile.size > MAX_FILE_SIZE) {
                    blockedFiles.push(fileName);
                } else {
                    acc.push({ name: fileName, file: selectedFile });
                }
                return acc;
            },
            []
        );

        if (blockedFiles.length > 0) {
            const badFileList = blockedFiles.join(", ");
            setFieldError(
                `${questionId}.fileNames`,
                `${badFileList} ${formatMessage({
                    defaultMessage: "cannot be uploaded because of file size (> 20MB)",
                })}`
            );
        }

        if (pendingFilesInfo.length < 1) {
            return;
        }

        let uniqueUploadedFileNames: string[] = [];
        const uploadPromises = pendingFilesInfo.map(async (pendingFileInfo) => {
            if (!answerId) return;
            // create signed upload url
            const data = await createUploadUrl({
                variables: {
                    input: {
                        referenceId: answerId,
                        fileName: pendingFileInfo.name,
                        metaJson: JSON.stringify({
                            origin: "assessment",
                            questionId: questionId,
                            assessmentId: assessmentId,
                        }),
                    },
                },
            });

            const url = data.data?.createAssessmentUploadURL.url;
            const uniqueFileName = data.data?.createAssessmentUploadURL.uniqueFileName;
            if (!url || !uniqueFileName) return;
            uniqueUploadedFileNames = [...uniqueUploadedFileNames, uniqueFileName];

            // upload the file to the signed url
            const headers = new Headers();
            headers.set("x-goog-meta-origin", "assessment");
            headers.set("x-goog-meta-questionId", questionId);
            if (assessmentId) {
                headers.set("x-goog-meta-assessmentId", assessmentId);
            }
            headers.set("Content-Type", "application/octet-stream");
            headers.set("x-goog-content-length-range", "0,20971520");

            return fetch(url, {
                method: "PUT",
                headers: headers,
                body: pendingFileInfo.file,
            }).catch((err) => {
                throw err;
            });
        });
        await Promise.all(uploadPromises).finally(() => {
            saveDraft(undefined, [...(answer?.fileNames ?? []), ...uniqueUploadedFileNames]);
        });
    };

    return (
        <Grid flexDirection="column" width="100%">
            {answer && answer.fileNames.length > 0 && (
                <Grid>
                    <FilesList
                        disabled={disabled}
                        fileNames={answer?.fileNames}
                        answerId={answer?.id}
                        setLoading={setLoading}
                        onFileRemove={(deleteFileName) => {
                            saveDraft(undefined, answer?.fileNames.filter((fileName) => fileName !== deleteFileName));
                        }}
                    />
                </Grid>
            )}
            {loading && (
                <Stack sx={{ width: "100%", paddingY: "1", height: "2.5rem", justifyContent: "center" }}>
                    {" "}
                    <LinearProgress sx={{ width: "100%" }} />{" "}
                </Stack>
            )}
            <Stack paddingBottom={0.5} width="100%" justifyContent="space-between">
                {touched && <Typography color="#d32f2f">{error}</Typography>}
                {uploadType === FileUpload.Optional && (
                    <Stack direction="row" justifyContent="flex-end">
                        <Typography variant="body2" color="warning">
                            {formatMessage({ defaultMessage: "Optional upload" })}
                        </Typography>
                    </Stack>
                )}
                {uploadType === FileUpload.Required && (
                    <Stack direction="row" justifyContent="flex-end">
                        <Typography variant="body2" color="warning">
                            {formatMessage({ defaultMessage: "Required upload" })}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            {!disabled && (
                <UploadWrapper sx={{ display: "flex" }}>
                    <DropZone>
                        <UploadInput
                            type="file"
                            multiple
                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                // clear the input value so that the onChange event will fire even if the user selects the same file
                                e.currentTarget.value = "";
                            }}
                            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                                setLoading(true);
                                setSubmitBlocked(true);
                                await handleFileChange(e.target.files);
                                setSubmitBlocked(false);
                                setLoading(false);
                            }}
                        />
                    </DropZone>
                </UploadWrapper>
            )}
        </Grid>
    );
};
