import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Chip, Skeleton, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AssessmentsOverview from "../../components/AssessmentsOverview";
import Layout from "../../components/Layout";
import MessagePage from "../../components/MessagePage";
import { GetCampaignDocument } from "../../generated/graphql";
import { campaignIsCompleted } from "../../globals/types";

dayjs.extend(LocalizedFormat);

const ID_PREFIX = "campaign-page-";

const CampaignPage: React.FC = () => {
    // get campaign with assessments
    const { data, loading, error } = useQuery(GetCampaignDocument);
    const { formatDate, formatMessage } = useIntl();

    // If request errored, or if there are no assessments on the campaign, show error page.
    if (error || !data || !data?.getCampaign.assessments) {
        if (loading) {
            return (
                <Layout>
                    <Stack direction="row" justifyContent="center" alignContent="center">
                        <Stack width="80vw">
                            <Card sx={{ minHeight: "70vh" }}>
                                <CardHeader sx={{ minHeight: "14rem", backgroundColor: "#ffebd2", padding: "2rem" }} />
                                <CardContent sx={{ minHeight: "20rem" }}>
                                    <Skeleton height="1+?%" width="100%" />
                                </CardContent>
                            </Card>
                        </Stack>
                    </Stack>
                </Layout>
            );
        }
        if (error) Sentry.captureException(error);
        if (!data || !data?.getCampaign.assessments) Sentry.captureException("no campaign data");
        return <MessagePage kind="error" reason="other" />;
    }

    const assessments = data?.getCampaign.assessments;
    const campaignName = `${formatMessage({ defaultMessage: "Campaign Name:" })} ${data.getCampaign?.name}`;
    const campaignCompleted = campaignIsCompleted(assessments);

    const welcomeMessage = `${formatMessage({ defaultMessage: "Welcome" })}${
        assessments[0].type === "external" ? `, ${assessments[0].supplier.name}` : "!"
    }`;

    return (
        <Layout>
            <Stack direction="row" justifyContent="center" alignContent="center">
                <Stack width="80vw">
                    <Card sx={{ minHeight: "70vh" }}>
                        <CardHeader
                            sx={{ height: "10vh", minHeight: "10rem", backgroundColor: "#ffebd2", padding: 2 }}
                            subheader={
                                <Stack alignItems="center" spacing={3}>
                                    <Stack alignItems="center" spacing={1}>
                                        <Typography color="#1D212B" variant="h6">
                                            {welcomeMessage}
                                        </Typography>
                                    </Stack>
                                    <Stack alignItems="center">
                                        <Typography data-testid={`${ID_PREFIX}dueDate`} variant="caption" noWrap>
                                            <FormattedMessage
                                                defaultMessage="Due date: {date}"
                                                values={{ date: formatDate(data.getCampaign?.dueDate) }}
                                            />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }
                        />
                        <CardContent>
                            <Stack padding={2} spacing={2}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography>{campaignName}</Typography>
                                    {assessments.length === 1 && (
                                        <Chip
                                            label={
                                                campaignCompleted
                                                    ? formatMessage({ defaultMessage: "Completed" })
                                                    : formatMessage({ defaultMessage: "Not Submitted" })
                                            }
                                            sx={{
                                                borderRadius: 1,
                                                backgroundColor: campaignCompleted ? "#DDFDF9" : "#D5E3FE",
                                            }}
                                        />
                                    )}
                                </Stack>
                                <Stack spacing={1}>
                                    <Stack direction="row" justifyContent="space-between" alignContent="center">
                                        <Typography variant="subtitle2">
                                            <FormattedMessage defaultMessage="Description" />
                                        </Typography>
                                    </Stack>
                                    <Stack padding={1}>
                                        <Typography
                                            sx={{ whiteSpace: "pre-line" }}
                                            variant="body2"
                                            data-testid={`${ID_PREFIX}description`}
                                        >
                                            {data.getCampaign?.description || ""}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack paddingTop={3}>
                                    <Stack direction="row" overflow="auto">
                                        <AssessmentsOverview assessments={assessments} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
        </Layout>
    );
};

export default CampaignPage;
