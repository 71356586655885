import { AppBar, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import logo from "../../assets/images/logo.svg";

interface Props {
    whiteRibbon?: boolean;
    children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ whiteRibbon, children }) => {
    return (
        <Stack>
            <AppBar position="sticky" elevation={0} sx={{ backgroundColor: "#FFFFFF", padding: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack>
                        <img src={logo} alt="Ignite" />
                    </Stack>
                    {window.location.href.indexOf("campaign") > -1 && (
                        <Stack justifyContent="center">
                            <Button
                                variant="text"
                                onClick={() =>
                                    window.open("https://www.ignite.no/lp/apenhetsloven-no", "noopener noreferrer")
                                }
                            >
                                <Typography color="black" sx={{ textDecoration: "underline" }}>
                                    <FormattedMessage defaultMessage="Not yet started working on the Transparency Act? We can help!" />
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </AppBar>
            {whiteRibbon && (
                <Stack
                    direction="row"
                    sx={{
                        position: "fixed",
                        backgroundColor: "#FFFFFF",
                        height: "16rem",
                        width: "100%",
                        zIndex: -1,
                    }}
                />
            )}
            <Container maxWidth="xl">
                <Stack paddingBottom={8} paddingX={2}>
                    {children}
                </Stack>
            </Container>
        </Stack>
    );
};

export default Layout;
