import { useMutation } from "@apollo/client";
import { Download } from "@ignite-analytics/icons";
import { Card, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import { CreateAssessmentDownloadUrlDocument } from "../../../generated/graphql";

type Props = {
    fileName: string;
    questionId: string;
};

const File: React.FC<Props> = ({ fileName, questionId }) => {
    const [createDownloadUrl] = useMutation(CreateAssessmentDownloadUrlDocument);
    const downloadFile = async () => {
        const urlData = await createDownloadUrl({
            variables: {
                input: {
                    referenceId: questionId,
                    fileName: fileName,
                },
            },
        });
        const url = urlData?.data?.createAssessmentDownloadURL?.url;
        if (!url) return;
        const fileBlob = fetch(url)
            .then((response) => {
                return response.blob();
            })
            .catch((err) => {
                throw err;
            });
        return fileBlob
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", `${fileName}`);
                link.setAttribute("disposition-type", "attachment");
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .catch((err) => {
                throw err;
            });
    };

    const displayFileName =
        fileName.length > 30 ? `${fileName.slice(0, 22)}...${fileName.slice(fileName.length - 5)}` : fileName;
    return (
        <Stack paddingLeft={1} paddingBottom={0.5}>
            <Tooltip title={fileName}>
                <Card sx={{ border: "solid 0.5px #D5E3FE", borderRadius: "12px", padding: 1 }} onClick={downloadFile}>
                    <Stack direction="row" justifyContent="center" alignContent="center" spacing={1}>
                        <Download fontSize="small" />
                        <Typography alignContent="center" variant="body1">
                            {displayFileName}
                        </Typography>
                    </Stack>
                </Card>
            </Tooltip>
        </Stack>
    );
};

export default File;
