import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

/** Input component for questions where the responder must select one out of several options. */
const SingleSelectQuestion: React.FC<QuestionProps> = ({ question, saveDraft, name, ...props }) => {
    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[question.id];
    const { touched } = getFieldMeta(`${question.id}.value`);

    if (!question.options || question.options.length < 1) {
        return null;
    }

    return (
        <FormControl sx={{ width: "100%" }} error={touched && Boolean(error)}>
            <RadioGroup
                {...props}
                value={values[question.id].value}
                name={name}
                onChange={(event) => {
                    setFieldValue(question.id, { value: event.target.value, fileNames: values[question.id].fileNames });
                    saveDraft([event.target.value]);
                }}
            >
                {question.options.map((option) => (
                    <FormControlLabel
                        value={option}
                        key={option}
                        control={<Radio disabled={props.disabled} />}
                        label={option}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>
                <Stack direction="row" justifyContent="flex-start">
                    <Typography>{touched && error ? error.value : ""}</Typography>
                </Stack>
            </FormHelperText>
        </FormControl>
    );
};

export default SingleSelectQuestion;
