import { Stack } from "@mui/material";
import React from "react";

import File from "./File";

type Props = {
    fileNames: string[];
    questionId: string;
};

export const AttachedFiles: React.FC<Props> = ({ fileNames, questionId }) => {
    return (
        <Stack direction="row" flexWrap="wrap">
            {fileNames.map((fileName) => (
                <File key={fileName} fileName={fileName} questionId={questionId} />
            ))}
        </Stack>
    );
};
