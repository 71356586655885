import { useQuery } from "@apollo/client";
import { Grid, Skeleton, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import AssessmentForm from "../../components/Form";
import Layout from "../../components/Layout";
import MessagePage from "../../components/MessagePage";
import QuestionnaireHeader from "../../components/Questionnaire/Header";
import SectionsNav from "../../components/SectionsNav";
import { GetAssessmentDocument } from "../../generated/graphql";
import useSectionIndex from "../../hooks/useSectionIndex";

export const QuestionnairePage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const preview = searchParams.get("preview") === "true";
    const { id } = useParams<{ id: string }>();
    const { data, loading, refetch, error } = useQuery(GetAssessmentDocument, {
        variables: { id: id ?? "" },
        /* Our signed download links expire after some time, polling is a quick workaround to this */
        pollInterval: 600_000, // 10 minutes
    });
    const questionnaire = data?.getAssessment.campaign.questionnaire;
    const [sectionIndex] = useSectionIndex();

    const questionCount = useMemo(
        () => questionnaire?.sections.reduce((sum, curr) => sum + curr.questions.length, 0),
        [questionnaire]
    );

    if (loading) {
        return (
            <Layout>
                <Stack direction="row" alignSelf="center" width="80vw" height="80vh">
                    <Skeleton height="100%" width="100%" />
                </Stack>
            </Layout>
        );
    }

    if (questionCount === 0) {
        return <MessagePage kind="error" reason="missingQuestionnaire" />;
    }

    // If request errored, or we fail to get questionnaire section, show error page.
    if (error || !data || questionnaire?.sections[sectionIndex] === undefined) {
        if (error) Sentry.captureException(error);
        if (!data || questionnaire?.sections[sectionIndex] === undefined) Sentry.captureException("no questionnaire data");
        return <MessagePage kind="error" reason="other" />;
    }

    const sideMenu = questionnaire?.sections?.length > 1;
    return (
        <Layout whiteRibbon>
            <QuestionnaireHeader questionnaire={questionnaire} supplierName={data.getAssessment.supplier.name} />
            <Grid container columnSpacing={{ xs: 0, sm: 0, md: 2 }} rowSpacing={{ xs: 2, sm: 2, md: 0 }} wrap="wrap">
                {sideMenu && (
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Stack minWidth="15rem" alignContent="center">
                            <SectionsNav questionnaire={questionnaire} />
                        </Stack>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={sideMenu ? 8 : 12} lg={sideMenu ? 9 : 12}>
                    <Stack>
                        <AssessmentForm assessment={data.getAssessment} preview={preview} refetch={refetch} />
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default QuestionnairePage;
