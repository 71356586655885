import { Button, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { basePath } from "../../containers/ApplicationPage/constants";
import { Assessment, isSubmitted } from "../../globals/types";

type Props = { assessments: Assessment[] };

const AssessmentsOverview: React.FC<Props> = ({ assessments }) => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const { formatMessage } = useIntl();

    return assessments.length === 1 ? (
        <Stack width="100%" direction="row" justifyContent="center" alignItems="center">
            <Stack width="15rem">
                <Button fullWidth onClick={() => navigate(`${basePath}/${assessments[0].id}?section=1&token=${token}`)}>
                    {isSubmitted(assessments[0])
                        ? formatMessage({ defaultMessage: "See your answer" })
                        : formatMessage({ defaultMessage: "Open" })}
                </Button>
            </Stack>
        </Stack>
    ) : (
        <Stack width="100%" height="100%">
            {assessments.map((assessment, index) => (
                <Stack width="100%" key={assessment.id} paddingTop={1} justifyItems="center">
                    <Grid container alignItems="center" rowSpacing={{ xs: 1, sm: 0 }}>
                        <Grid container item xs={12} sm={10} md={8} alignItems="center" rowSpacing={{ xs: 1, sm: 0 }}>
                            <Grid item xs={12} sm={8} md={6}>
                                <Typography variant="subtitle1">{assessment.supplier.name}</Typography>
                            </Grid>
                            <Grid container item xs={12} sm={4} md={6} alignItems="center">
                                <Chip
                                    label={
                                        isSubmitted(assessment)
                                            ? formatMessage({ defaultMessage: "Completed" })
                                            : formatMessage({ defaultMessage: "Not Submitted" })
                                    }
                                    sx={{
                                        borderRadius: 1,
                                        backgroundColor: isSubmitted(assessment) ? "#DDFDF9" : "#D5E3FE",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} sm={2} md={4} justifyContent="flex-end" alignItems="center">
                            <Button onClick={() => navigate(`${basePath}/${assessment.id}?section=1&token=${token}`)}>
                                {isSubmitted(assessment)
                                    ? formatMessage({ defaultMessage: "See your answer" })
                                    : formatMessage({ defaultMessage: "Open" })}
                            </Button>
                        </Grid>
                    </Grid>
                    {index < assessments.length - 1 && <Divider sx={{ paddingTop: 1 }} />}
                </Stack>
            ))}
        </Stack>
    );
};

export default AssessmentsOverview;
