import * as yup from "yup";

export function initializeYup(locale: string) {
    if (locale === "en-US") {
        yup.setLocale({
            mixed: {
                required: () => `This question is required`,
            },
            string: {
                min: ({ min }) => `The answer must be at least ${min} characters`,
                max: ({ max }) => `The answer cannot be more than ${max} characters`,
            },
            number: {
                min: ({ min }) => `The answer must be at least ${min}`,
                max: ({ max }) => `The answer cannot be more than ${max}`,
            },
            array: {
                min({ min, path }) {
                    if (path.endsWith("fileNames")) {
                        return `You must attach at least ${min} file(s)`;
                    }
                    return `You must select at least ${min} option(s)`;
                },
            },
        });
    } else {
        yup.setLocale({
            mixed: {
                required: () => `Dette spørsmålet er påkrevd`,
            },
            string: {
                min: ({ min }) => `Svaret må inneholde minst ${min} tegn`,
                max: ({ max }) => `Svaret kan ikke inneholde mer enn ${max} tegn`,
            },
            number: {
                min: ({ min }) => `Svaret må være minst ${min}`,
                max: ({ max }) => `Svaret kan ikke være større enn ${max}`,
            },
            array: {
                min({ min, path }) {
                    if (path.endsWith("fileNames")) {
                        return `Du må legge ved minst ${min} fil(er)`;
                    }
                    return `Du må velge minst ${min} valg`;
                },
            },
        });
    }
}
