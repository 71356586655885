import { useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { CreateAssessmentDownloadUrlDocument } from "../../../generated/graphql";
import OverflowTooltip from "../OverflowTooltip";

interface Props {
    fileName: string;
    answerId: string;
    onDeleteFile: (fileName: string) => void;
    disabled?: boolean;
}

export const FileListItem = ({ fileName, onDeleteFile: deleteFile, answerId, disabled = false }: Props) => {
    const [createDownloadUrl] = useMutation(CreateAssessmentDownloadUrlDocument);

    const downloadFile = () => {
        createDownloadUrl({
            variables: {
                input: {
                    referenceId: answerId,
                    fileName: fileName,
                },
            },
            onCompleted: (data) => {
                const url = data?.createAssessmentDownloadURL?.url;
                if (!url) return;
                const fileBlob = fetch(url)
                    .then((response) => {
                        return response.blob();
                    })
                    .catch((err) => {
                        throw err;
                    });
                fileBlob
                    .then((blob) => {
                        const blobUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = blobUrl;
                        link.setAttribute("download", `${fileName}`);
                        link.setAttribute("disposition-type", "attachment");
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode?.removeChild(link);
                    })
                    .catch((err) => {
                        throw err;
                    });
            },
        });
    };

    return (
        <Grid display="flex" alignItems="center" justifyContent="space-between">
            <Box sx={{ alignItems: "center", flex: 1, overflow: "hidden", display: "flex" }}>
                <OverflowTooltip text={fileName} />
            </Box>

            <Grid justifyContent="flex-end" display="flex">
                <IconButton data-testid="file-download-button" size="small" onClick={downloadFile}>
                    <FileDownloadIcon />
                </IconButton>
                {!disabled && (
                    <IconButton data-testid="file-delete-button" size="small" onClick={() => deleteFile(fileName)}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};
