import EmailIcon from "@mui/icons-material/Email";
import { Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { Questionnaire, User } from "../../../globals/types";

type Props = {
    questionnaire?: Questionnaire;
    user?: User;
    supplierName?: string;
};

const QuestionnaireHeader: React.FC<Props> = ({ questionnaire, user, supplierName }) => {
    const numberOfQuestions = useMemo(
        () => questionnaire?.sections.reduce((prevVal, currVal) => prevVal + currVal.questions.length, 0),
        [questionnaire]
    );
    const { formatMessage } = useIntl();

    return (
        <Stack gap={1} paddingY={3} paddingX={{ xs: 0.5, sm: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{questionnaire?.name}</Typography>
                <Typography variant="h6">{supplierName}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                    {formatMessage(
                        { defaultMessage: "Number of Questions: {number}" },
                        {
                            number: numberOfQuestions,
                        }
                    )}
                </Typography>
                {user && (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <EmailIcon fontSize="small" />
                        <Typography>{user?.email}</Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default QuestionnaireHeader;
