import { ResultOf } from "@graphql-typed-document-node/core";

import {
    AnswerFieldsFragment,
    CampaignFieldsFragment,
    GetCampaignDocument,
    QuestionFieldsFragment,
    QuestionnaireFieldsFragment,
    SectionFieldsFragment,
} from "../generated/graphql";

export type Assessment = ResultOf<typeof GetCampaignDocument>["getCampaign"]["assessments"][0];

export const isSubmitted = (assessment: Pick<Assessment, "submittedAt"> | undefined): boolean => {
    return Boolean(assessment?.submittedAt);
};

export const campaignIsCompleted = (assessments: Pick<Assessment, "submittedAt">[]): boolean => {
    // if one is not submitted -> then the campaign isn't completed
    const notCompleted = assessments.find((assessment) => !isSubmitted(assessment));
    return !notCompleted;
};

export type Campaign = CampaignFieldsFragment;

export type Questionnaire = QuestionnaireFieldsFragment;

export type Section = SectionFieldsFragment;

export type Answer = AnswerFieldsFragment;

export type Question = QuestionFieldsFragment;

export type AssessmentFile = {
    name: string;
    client: number;
    contentType: string;
    createdAt: string;
    department: number;
    file: string;
    id: number;
    objectId: number;
    updatedAt: string;
};

/** Type of the `values` field on the Formik form for a questionnaire. */
export type FormValues = { [key: string]: FieldValue };

/** Type of a value on the Formik form for a questionnaire. */
export type FieldValue = string | string[];

interface EmailSignature {
    id?: number;
    content: string;
    user: string;
}

interface UserSettings {
    id: number;
    user: string;
    language: string;
    AVAILABLE_LANGUAGES: [string, string][];
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    client: number;
    email: string;
    username: string;
    published: boolean;
    signature: EmailSignature;
    settings: UserSettings;
    isStaff: boolean;
    isSuperuser: boolean;
    profileImage?: string;
    profilePicture?: string;
    departmentIds: number[];
    groups: string[];
    createdAt?: string;
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Intercom: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        heap: any;
    }
}
