import { track as igniteTrack, identify as igniteIdentify } from "@ignite-analytics/track";

/**
 * Tracks any event in the application.
 *
 * @param event Event to be tracked
 * @param properties Other relevant properties for the event
 */
export function track(event: string, properties?: Record<string, unknown>) {
    try {
        igniteTrack(event, properties);
    } catch (e) {
        console.error(e);
    }
}

/**
 * Identify user before tracking events.
 */
export function identify(userId: string) {
    try {
        igniteIdentify({ email: userId, type: "anonymous" });
    } catch (e) {
        console.error(e);
    }
}
