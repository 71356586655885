import { ApolloQueryResult, useMutation } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Card } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";

import {
    GetAssessmentDocument,
    GetAssessmentQuery,
    Question,
    ValidateAndSubmitAssessmentDocument,
} from "../../generated/graphql";
import useSectionIndex from "../../hooks/useSectionIndex";
import { track } from "../../lib/track";
import ErrorDialog from "../Questionnaire/ErrorDialog";
import Footer from "../Questionnaire/Footer";

import { answerListToMap, getInitialAnswerValues } from "./helpers";
import Section from "./Section";
import { SubmittedModal } from "./SubmittedModal";
import { createValidationSchema } from "./yup-helpers";

type Props = {
    assessment: ResultOf<typeof GetAssessmentDocument>["getAssessment"];
    preview?: boolean;
    refetch: () => Promise<ApolloQueryResult<GetAssessmentQuery>>;
};

const AssessmentForm: React.FC<Props> = ({ assessment, preview, refetch }) => {
    const [open, setOpen] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [missingAnswers, setMissingAnswers] = useState<Question[]>([]);
    const [submitBlocked, setSubmitBlocked] = useState(false);

    const [submitAssessment] = useMutation(ValidateAndSubmitAssessmentDocument, {
        variables: {
            assessmentId: assessment.id,
        },
        onCompleted: (res) => {
            if (!res.validateAndSubmitAssessment.ok) {
                setMissingAnswers(res.validateAndSubmitAssessment.missingResponse as Question[]);
                setErrorSubmitting(true);
                return;
            }
            setOpen(true);
        },
        onError: () => {
            setErrorSubmitting(true);
        },
    });

    const [sectionIndex] = useSectionIndex();
    const { questionnaire } = assessment.campaign;
    const section = questionnaire.sections[sectionIndex];

    const answers = answerListToMap(assessment.answers);
    const initialValues = getInitialAnswerValues(questionnaire, answers);
    const validationSchema = useMemo(() => createValidationSchema(questionnaire.sections), [questionnaire.sections]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    if (preview) return;
                    track("submitted form", { type: assessment.type });
                    submitAssessment();
                }}
            >
                <Form style={{ width: "100%" }}>
                    <Card sx={{ border: "solid 0.5px #D5E3FE", borderRadius: "12px", padding: 2 }}>
                        <Section
                            key={section.id}
                            section={section}
                            preview={preview}
                            answers={answers}
                            disabled={!!assessment.submittedAt}
                            setSubmitBlocked={setSubmitBlocked}
                        />
                    </Card>
                    {!assessment.submittedAt && (
                        <Footer
                            sectionCount={questionnaire.sections.length}
                            disabled={submitBlocked}
                            refetch={refetch}
                        />
                    )}

                    <ErrorDialog
                        errorSubmitting={errorSubmitting}
                        missingAnswers={missingAnswers}
                        closeModal={() => {
                            setOpen(false);
                            setErrorSubmitting(false);
                        }}
                        questionnaire={questionnaire}
                    />
                </Form>
            </Formik>
            <SubmittedModal open={open} type={assessment.type} />
        </>
    );
};

export default AssessmentForm;
