import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faCheck,
    faEnvelope,
    faExclamation,
    faFileDownload,
    faHourglassStart,
    faPaperclip,
    faQuestion,
    faSearch,
    faStar,
    faTimes,
    faTrashAlt,
    faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { getDesignTokens } from "@ignite-analytics/theme";
import { createTheme, ThemeProvider } from "@mui/material";
import { enUS } from "@mui/material/locale";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";

import FatalErrorFallback from "./components/FatalErrorBoundary";
import { ApplicationPage } from "./containers/ApplicationPage";
import { loadMessages } from "./lib/i18n/loadMessages";
import { identify } from "./lib/track";
import { initializeYup } from "./lib/yup";

if (process.env.REACT_APP_HEAP_APP_ID) {
    window.heap.load(process.env.REACT_APP_HEAP_APP_ID);
}

library.add(
    faStar,
    faEnvelope,
    faPaperclip,
    faCheck,
    faExclamation,
    faHourglassStart,
    faUpload,
    faFileDownload,
    faSearch,
    faAngleDown,
    faQuestion,
    faTimes,
    faTrashAlt
);

function getAppDsn() {
    return process.env.REACT_APP_ASSESSMENT_FORM_SENTRY_DSN
        ? [{ dsn: process.env.REACT_APP_ASSESSMENT_FORM_SENTRY_DSN, release: "" }]
        : [];
}

function App() {
    const norwegianOptions = ["no", "nb", "nn", "nb-NO", "nn-NO", "nbNO", "nnNO", "nb_NO", "nn_NO", "nbNO", "nnNO"];

    const locale = norwegianOptions.includes(navigator.languages[0]) ? "nb-NO" : "en-US";
    const theme = "bigbang";
    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);

    initializeYup(locale);

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        transport: Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, getAppDsn),
        beforeSend(event, _) {
            event.tags = event.tags || {};
            event.tags.tenant = localStorage.tenant ?? "";
            return event;
        },
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.6,
        enabled: process.env.NODE_ENV != "development",
    });

    // adding tracking id
    identify("forms-app-respondent");

    return (
        <React.StrictMode>
            <ThemeProvider theme={createTheme(designTokens, enUS)}>
                <IntlProvider
                    locale={locale}
                    messages={loadMessages(locale)}
                    onError={(e) => {
                        if (e.code !== "MISSING_TRANSLATION") {
                            console.error(e);
                        }
                    }}
                >
                    <Sentry.ErrorBoundary fallback={<FatalErrorFallback />}>
                        <BrowserRouter>
                            <ApplicationPage />
                        </BrowserRouter>
                    </Sentry.ErrorBoundary>
                </IntlProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
}

export default App;
