import { Button, Card, CardContent, CardActions, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { basePath } from "../../containers/ApplicationPage/constants";
import useToken from "../../hooks/useToken";
import Layout from "../Layout";

import errorImage from "./errorImage.svg";
import sentImage from "./sentImage.svg";

type Props = {
    kind: "success" | "error";
    reason: "404" | "completedAssessment" | "completedCampaign" | "missingQuestionnaire" | "other";
};

const MessagePage: React.FC<Props> = ({ kind = "error", reason = "other" }) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const { formatMessage } = useIntl();

    const cases: Record<Props["reason"], { header: string; description: string }> = {
        "404": {
            header: formatMessage({ defaultMessage: "Invalid URL" }),
            description: formatMessage({ defaultMessage: "Please check the spelling of the URL and try again." }),
        },
        completedAssessment: {
            header: formatMessage({ defaultMessage: "Thank you for submitting your assessment!" }),
            description: formatMessage({ defaultMessage: "You can now close this window." }),
        },
        completedCampaign: {
            header: formatMessage({ defaultMessage: "Assessment sent!" }),
            description: formatMessage({ defaultMessage: "You can close this window or start a new assessment." }),
        },
        missingQuestionnaire: {
            header: formatMessage({ defaultMessage: "This questionnaire does not have any questions!" }),
            description: formatMessage({ defaultMessage: "You should let the person that sent it know." }),
        },
        other: {
            header: formatMessage({ defaultMessage: "Failed to load data." }),
            description: formatMessage({
                defaultMessage: "There has been an error while processing your request. Please try again later.",
            }),
        },
    };

    const { header, description } = cases[reason];

    const backToCampaign = () => {
        navigate(`${basePath}/?token=${token}`);
    };
    const colors = {
        success: {
            backgroundColor: "#E9FEFA",
        },
        error: {
            backgroundColor: "#FF9B9B",
        },
    } as const;

    return (
        <Layout>
            <Stack direction="row" justifyContent="center" alignContent="center">
                <Stack>
                    <Card sx={{ backgroundColor: colors[kind] }}>
                        <Stack margin={{ bottom: "l" }}>
                            <img src={kind === "success" ? sentImage : errorImage} alt="" width="100%" />
                        </Stack>
                        <CardContent>
                            <Typography variant="h6">{header}</Typography>
                            <Typography variant="subtitle1" alignContent="left">
                                {description}
                            </Typography>
                        </CardContent>
                        {kind === "success" && (
                            <CardActions sx={{ justifyContent: "center" }}>
                                <Stack sx={{ padding: 2 }}>
                                    <Button onClick={backToCampaign} color="secondary">
                                        <FormattedMessage defaultMessage="Back to campaign" />
                                    </Button>
                                </Stack>
                            </CardActions>
                        )}
                    </Card>
                </Stack>
            </Stack>
        </Layout>
    );
};

export default MessagePage;
