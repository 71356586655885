import { ApolloCache, DefaultContext, MutationUpdaterFunction, NormalizedCacheObject, Reference } from "@apollo/client";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

import { AnswerFieldsFragmentDoc, SendAnswerDocument } from "../../generated/graphql";

export const addAnswerToAssessmentCache: MutationUpdaterFunction<
    ResultOf<typeof SendAnswerDocument>,
    VariablesOf<typeof SendAnswerDocument>,
    DefaultContext,
    ApolloCache<NormalizedCacheObject>
> = (cache, { data }) => {
    if (data) {
        const { assessmentId } = data.sendAnswer;
        cache.modify({
            id: `Assessment:${assessmentId}`,
            fields: {
                answers(existingAnswers, { readField }) {
                    const exists = existingAnswers.some(
                        (ref: Reference) => readField("questionId", ref) === data.sendAnswer.questionId
                    );
                    if (exists) {
                        return existingAnswers;
                    }

                    const newAnswerRef = cache.writeFragment({
                        data: data.sendAnswer,
                        fragment: AnswerFieldsFragmentDoc,
                        fragmentName: "AnswerFields",
                    });
                    return [...existingAnswers, newAnswerRef];
                },
            },
        });
    }
};
