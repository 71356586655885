import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject, makeVar } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";

import { Status } from "../generated/graphql";

export const sectionIndexVar = makeVar(NaN);

const createApolloCache = () => {
    return new InMemoryCache({
        typePolicies: {
            Answer: {
                // Answer's in the cache are identified by the questionId, as we have a unique answer per question.
                keyFields: ["questionId"],
            },
            FileResponse: {
                fields: {
                    // Reads the local field `status`, defaults to success as files without `status` are
                    // retrived from the DB, and those are all successful (eg. from a prior visit to the asssessment)
                    status: {
                        read(existing) {
                            if (existing) return existing;
                            return Status.Success;
                        },
                    },
                },
            },
            Query: {
                fields: {
                    sectionIndex: {
                        read() {
                            return sectionIndexVar;
                        },
                    },
                },
            },
        },
    });
};

const createApolloClient = (uri?: string, token?: string): ApolloClient<NormalizedCacheObject> => {
    const httpLink = new HttpLink({
        uri,
        credentials: "include",
    });

    const authLink = setContext((_, { headers = {} }) => {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${token ?? localStorage.getItem("token")}`,
            },
        };
    });

    return new ApolloClient({
        connectToDevTools: process.env.NODE_ENV !== "production",
        credentials: "include",
        link: from([authLink, httpLink]),
        cache: createApolloCache(),
    });
};

export const useApollo = (uri?: string, token?: string) => {
    return useMemo(() => createApolloClient(uri, token), [uri, token]);
};
