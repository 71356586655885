import { HeartBroken } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const FatalErrorFallback: React.FC = () => (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
        <Alert
            severity="error"
            sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", width: "60%" }}
            icon={false}
        >
            <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                    <HeartBroken fontSize="large" sx={{ marginBottom: "10px" }} />
                    <AlertTitle sx={{ fontSize: 38 }}>
                        {<FormattedMessage defaultMessage={"Something went wrong!"} />}
                    </AlertTitle>
                </Stack>
                <Typography sx={{ fontSize: 22 }}>
                    {
                        <FormattedMessage
                            defaultMessage={"Our team has been informed. You can attempt to refresh the page."}
                        />
                    }
                </Typography>
                <Button size="large" onClick={() => window.location.reload()}>
                    {<FormattedMessage defaultMessage={"Refresh page"} />}
                </Button>
            </Stack>
        </Alert>
    </Stack>
);

export default FatalErrorFallback;
