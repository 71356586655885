import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
    FormControl,
    FormHelperText,
    Rating as MUIRating,
    RatingProps,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

const StyledRating = styled(MUIRating)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const Rating = (props: RatingProps) => {
    return <StyledRating {...props} icon={<StarBorderOutlinedIcon fontSize="inherit" />} />;
};

const RatingQuestion: React.FC<QuestionProps> = ({ question, saveDraft, ...props }) => {
    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[question.id];

    const { touched } = getFieldMeta(`${question.id}.value`);
    if (question.maxRating) {
        return (
            <FormControl sx={{ width: "100%" }} error={touched && Boolean(error)}>
                <Rating
                    {...props}
                    max={question.maxRating}
                    value={Number(values[question.id].value)}
                    onChange={(_, v) => {
                        if (!v) return;
                        setFieldValue(question.id, { value: v.toString(), fileNames: values[question.id].fileNames });
                        saveDraft([v.toString()]);
                    }}
                    size="large"
                />
                <FormHelperText>
                    <Stack direction="row" justifyContent="flex-start">
                        <Typography>{touched && error ? error.value : ""}</Typography>
                    </Stack>
                </FormHelperText>
            </FormControl>
        );
    }
    return null;
};

export default RatingQuestion;
