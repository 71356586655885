import type en from "./compiled/en-US.json";
import enUS from "./compiled/en-US.json";
import nbNO from "./compiled/nb-NO.json";

export type LocaleMessages = Partial<Omit<typeof en, "default">>;

function loadMessages(locale: string): LocaleMessages {
    switch (locale) {
        case "en-US":
            return enUS;
        case "nb-NO":
            return nbNO;
        default:
            return enUS;
    }
}

export { loadMessages };
