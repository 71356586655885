import { debounce, FormControl, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

const NumberQuestion: React.FC<QuestionProps> = ({ saveDraft, ...props }) => {
    const debounceFunc = useMemo(() => {
        return debounce((val) => saveDraft([val]), 1000);
    }, [saveDraft]);

    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[props.question.id];

    const { touched } = getFieldMeta(`${props.question.id}.value`);

    return (
        <FormControl sx={{ width: "100%" }}>
            <TextField
                {...props}
                name={props.question.title}
                label=""
                fullWidth
                type="number"
                error={touched && Boolean(error)}
                value={values[props.question.id].value}
                onChange={(event) => {
                    setFieldValue(props.question.id, {
                        value: event.target.value,
                        fileNames: values[props.question.id].fileNames,
                    });
                    debounceFunc(event.target.value);
                }}
                helperText={
                    <Stack direction="row" justifyContent="flex-start">
                        <Typography>{touched && error ? error.value : ""}</Typography>
                    </Stack>
                }
            />
        </FormControl>
    );
};

export default NumberQuestion;
