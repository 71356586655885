import { QuestionType } from "../../generated/graphql";
import { Answer, Question, Questionnaire } from "../../globals/types";

export type initialValue = {
    value: string | string[];
    fileNames: string[];
};

export const answerListToMap = (answerList: Answer[]) => {
    return answerList.reduce<Record<string, Answer>>((answerMap, answer) => {
        return { ...answerMap, [answer.questionId]: answer };
    }, {});
};

const getInitialAnswerValue = (question: Question, answers: Record<string, Answer>): initialValue => {
    switch (question.type) {
        case QuestionType.MultiSelect:
            return { value: answers[question.id]?.value ?? [], fileNames: answers[question.id]?.fileNames ?? [] };
        default:
            return { value: answers[question.id]?.value[0] ?? "", fileNames: answers[question.id]?.fileNames ?? [] };
    }
};

/**
 * Map the value of answers to their related question id
 * in order to create a valid initial value object for Formik
 */
export const getInitialAnswerValues = (
    questionnaire: Questionnaire,
    answers: Record<string, Answer>
): Record<string, initialValue> => {
    return questionnaire.sections.reduce<Record<string, initialValue>>((prev, current) => {
        return {
            ...prev,
            ...current.questions.reduce<Record<string, initialValue>>((prevAnswers, question) => {
                return {
                    ...prevAnswers,
                    [question.id]: getInitialAnswerValue(question, answers),
                };
            }, {}),
        };
    }, {});
};
