import JwtDecode from "jwt-decode";
import { useSearchParams } from "react-router-dom";


const useToken = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")!;
    const { ...decoded } = decode(token);

    return { token, decoded };
};


/* eslint-disable-next-line camelcase */
type JwtToken = { campaignId?: string; tenant?: string };

export const decode = (token: string | null | undefined): JwtToken => {
    if (!token)
        return {
            campaignId: undefined,
        };
    return {
        ...JwtDecode<JwtToken>(token),
    };
};


export default useToken;
