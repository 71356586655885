import React, { useEffect, useContext } from "react";

import { userContext } from "../../globals/helpers/userContext";

import { APP_ID } from "./constants";

interface IntercomProps {
    children?: React.ReactNode;
}

export const IntercomMessenger: React.FC<IntercomProps> = ({ children }) => {
    const { user } = useContext(userContext);

    const intercomOpts = React.useMemo(() => {
        return {
            app_id: APP_ID,
            api_base: process.env.REACT_APP_INTERCOM_APP_EU_URL,
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
            user_id: user?.email,
            created_at: user?.createdAt,
        };
    }, [user]);
    useEffect(() => {
        window.Intercom("boot", intercomOpts);
        // Naive user-login event, will retrigger on reload
        window.Intercom("trackEvent", "user-login");
    }, [intercomOpts]);

    useEffect(() => {
        window.Intercom("update", intercomOpts);
    }, [intercomOpts]);

    return <>{children}</>;
};

export const IntercomProvider: React.FC<IntercomProps> = ({ children }) => {
    if (process.env.NODE_ENV !== "production") {
        return <>{children}</>;
    }

    return <IntercomMessenger>{children}</IntercomMessenger>;
};
