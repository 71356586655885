import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { IntercomProvider } from "../../components/IntercomMessenger";
import MessagePage from "../../components/MessagePage";
import useToken from "../../hooks/useToken";
import { useApollo } from "../../lib/apollo";
import CampaignPage from "../CampaignPage";
import QuestionnairePage from "../QuestionnairePage";

import { basePath } from "./constants";

const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;

/**
 * New version of the assessment forms app, shown if the appropriate feature toggle is toggled.
 *
 * Once the backend has been fully migrated from the old Django API to the assessments microservice:
 * - Remove the feature-toggle-wrapping `ApplicationPage` below
 * - Rename `NewApplicationPage` -> `ApplicationPage` and export this instead
 */
export const ApplicationPage: React.FC = () => {
    const { token } = useToken();
    const client = useApollo(GRAPHQL_URI, token);

    return (
        <ApolloProvider client={client}>
            <IntercomProvider>
                <Routes>
                    <Route path={basePath} element={<CampaignPage />} />
                    <Route path={`${basePath}/campaign/:campaignId/`} element={<CampaignPage />} />
                    <Route
                        path={`${basePath}/submitted`}
                        element={<MessagePage kind="success" reason="completedAssessment" />}
                    />

                    <Route path={`${basePath}/:id/`} element={<QuestionnairePage />} />
                    <Route path={`${basePath}/*`} element={<MessagePage kind="error" reason="404" />} />
                    <Route path="*" element={<MessagePage kind="error" reason="404" />} />
                    <Route path="/" element={<MessagePage kind="error" reason="404" />} />
                </Routes>
            </IntercomProvider>
        </ApolloProvider>
    );
};
