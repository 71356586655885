import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

interface OverflowTooltipProps {
    text: string;
}

const OverflowTooltip = ({ text }: OverflowTooltipProps) => {
    // Create Ref
    const textElementRef = React.useRef<HTMLDivElement>(null);
    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    const compareSize = () => {
        if (textElementRef.current) {
            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setHover(compare);
        }
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);

        return () => {
            window.removeEventListener("resize", compareSize);
        };
    }, []);

    return (
        <Tooltip title={text} disableHoverListener={!hoverStatus}>
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <Typography variant="body1">{text}</Typography>
            </div>
        </Tooltip>
    );
};

export default OverflowTooltip;
