import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";

import { QuestionType } from "../../../generated/graphql";
import { Question } from "../../../globals/types";
import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

type Props = {
    question: Question;
};

const ID_PREFIX = "questionnaire-";

const blankValue = (question: Question) => {
    switch (question.type) {
        case QuestionType.MultiSelect:
            return [];
        default:
            return "";
    }
};

/**
 * Button for clearing an answer to a question in a questionnaire.
 * Takes the existing `formValue` of the field to determine the type of blank value to set,
 * and `setFormValue` to clear the value.
 */
const ClearAnswer: React.FC<Props & Pick<QuestionProps, "saveDraft">> = ({ question, saveDraft }) => {
    const { setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const onClick = () => {
        setFieldValue(question.id, {
            value: blankValue(question),
        });
        saveDraft([], []);
    };

    return (
        <Button
            onClick={onClick}
            sx={{ color: "grey" }}
            variant="text"
            data-testid={`${ID_PREFIX}${question.id}-clearAnswerButton`}
        >
            <FormattedMessage defaultMessage="Clear answer" />
        </Button>
    );
};

export default ClearAnswer;
