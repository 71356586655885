import { useMutation } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";

import { FileUpload, QuestionType, SendAnswerDocument } from "../../generated/graphql";
import { Answer, Question as TQuestion } from "../../globals/types";
import { Files } from "../Files";
import { initialValue } from "../Form/helpers";

import { AttachedFiles } from "./AttachedFiles";
import BooleanQuestion from "./BooleanQuestion";
import ClearAnswer from "./ClearAnswer";
import DateQuestion from "./DateQuestion";
import { QuestionProps } from "./interfaces";
import MultiSelectQuestion from "./MultiSelectQuestion";
import NumberQuestion from "./NumberQuestion";
import RatingQuestion from "./RatingQuestion";
import SingleSelectQuestion from "./SingleSelectQuestion";
import { addAnswerToAssessmentCache } from "./state";
import TextQuestion from "./TextQuestion";

const ID_PREFIX = "question-";

export type PreviewProps = { preview: true } | { preview?: false; assessmentId?: string };

type Props = {
    question: TQuestion;
    answer?: Answer;
    number: number;
    disabled?: boolean;
    setSubmitBlocked: React.Dispatch<React.SetStateAction<boolean>>;
    "data-testid"?: string;
} & PreviewProps;

const QuestionBody: React.FC<
    Pick<Props, "question" | "answer" | "disabled" | "data-testid"> & Pick<QuestionProps, "saveDraft" | "name">
> = ({ question, answer, disabled, saveDraft, name, "data-testid": dataTestId }) => {
    const questionProps = {
        name,
        question,
        answer,
        saveDraft,
        disabled,
        "data-testid": dataTestId,
        "aria-labelledby": `${name}-title`,
        "aria-describedby": `${name}-description`,
    };

    switch (question.type) {
        case QuestionType.Text:
            return <TextQuestion {...questionProps} />;
        case QuestionType.Boolean:
            return <BooleanQuestion {...questionProps} />;
        case QuestionType.Date:
            return <DateQuestion {...questionProps} />;
        case QuestionType.Number:
            return <NumberQuestion {...questionProps} />;
        case QuestionType.SingleSelect:
            return <SingleSelectQuestion {...questionProps} />;
        case QuestionType.MultiSelect:
            return <MultiSelectQuestion {...questionProps} />;
        case QuestionType.Rating:
            return <RatingQuestion {...questionProps} />;
        default:
            return <TextQuestion {...questionProps} />;
    }
};

const Question: React.FC<Props> = ({ number, ...props }) => {
    const { getFieldHelpers } = useFormikContext<Record<string, initialValue>>();
    const { setTouched: setFileNamesTouched, setValue: setFileNames } = getFieldHelpers<string[]>(
        `${props.question.id}.fileNames`
    );
    const { setTouched: setValueTouched } = getFieldHelpers<string[]>(`${props.question.id}.value`);
    const { id } = useParams<{ id: string }>();

    // Further destructuring here in order to pass props to QuestionBody.
    const { question, answer, setSubmitBlocked } = props;
    const name = `${question.id}.value`;
    const dataTestId = `${ID_PREFIX}${question.type}-${question.id}`;

    const [sendAnswer, { error }] = useMutation(SendAnswerDocument, {
        update: addAnswerToAssessmentCache,
        onCompleted: ({ sendAnswer: { fileNames } }) => {
            if (fileNames !== undefined) {
                setFileNames(fileNames);
            }
        },
    });

    /** Save draft answer and upload files. */
    const saveDraft: QuestionProps["saveDraft"] = async (value, fileNames) => {
        if (props.preview) return "";
        const data = await sendAnswer({
            variables: {
                questionId: question.id,
                assessmentId: id ?? "",
                value: value ?? answer?.value,
                fileNames: fileNames ?? answer?.fileNames,
                /**
                 * Only include the updated field in the response,
                 * otherwise we might overwrite files that are being uploaded.
                 * If `answer` is undefined, we're creating a new answer, so
                 * we fetch all fields.
                 */
                includeValue: value !== undefined || answer === undefined,
                includeFileNames: fileNames !== undefined || answer === undefined,
            },
        });
        if (value) setValueTouched(true);
        if (fileNames) setFileNamesTouched(true);
        return data.data?.sendAnswer.id ?? "";
    };

    return (
        <Stack width="100%" spacing={1}>
            <Stack direction="row" alignContent="center" justifyContent="space-between" width="100%" alignSelf="center">
                <Stack direction="row" spacing={2} alignSelf="center">
                    <Typography variant="subtitle1">
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        {number}. {question.title} {question.required && "*"}
                    </Typography>
                    {error && (
                        <Typography variant="subtitle1" color="red">
                            <FormattedMessage defaultMessage="Error uploading answer. Please clear and retry" />
                        </Typography>
                    )}
                </Stack>
                {!props.disabled && (
                    <Stack direction="row" spacing={2}>
                        <ClearAnswer question={question} saveDraft={saveDraft} />
                    </Stack>
                )}
            </Stack>
            {!!question.description && (
                <Stack>
                    <Typography sx={{ whiteSpace: "pre-line" }} variant="subtitle2" color="tertiary">
                        {question.description}
                    </Typography>
                </Stack>
            )}
            {question.attachedFileNames.length > 0 && (
                <Stack spacing={1}>
                    <Typography variant="subtitle2">
                        <FormattedMessage defaultMessage="Attachments" />
                    </Typography>
                    <Stack direction="row">
                        <AttachedFiles fileNames={question.attachedFileNames} questionId={question.id} />
                    </Stack>
                </Stack>
            )}
            <Stack direction="row" paddingX={2}>
                <QuestionBody
                    saveDraft={saveDraft}
                    {...props}
                    name={name}
                    data-testid={dataTestId}
                    disabled={props.disabled}
                />
            </Stack>
            {question.fileUpload !== FileUpload.Disabled && (
                <Stack direction="row" paddingX={2}>
                    <Files
                        answer={answer}
                        saveDraft={saveDraft}
                        questionId={question.id}
                        uploadType={question.fileUpload}
                        disabled={props.disabled}
                        setSubmitBlocked={setSubmitBlocked}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default Question;
