import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

/** Input component for questions where the responder can select several options as their answer. */
const MultiSelectQuestion: React.FC<QuestionProps> = ({ question, saveDraft, name, ...props }) => {
    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();

    const { value } = values[question.id];
    const error = errors[question.id];
    const { touched } = getFieldMeta(`${question.id}.value`);

    if (!question.options || question.options.length < 1) {
        return null;
    }

    const handleChange = (option: string) => {
        const newList = value.includes(option) ? (value as string[]).filter((v) => v !== option) : [...value, option];
        setFieldValue(question.id, { value: newList, fileNames: values[question.id].fileNames });
        saveDraft(newList);
    };

    return (
        <FormControl sx={{ width: "100%" }} error={touched && Boolean(error)}>
            <FormGroup {...props}>
                {question.options.map((option) => (
                    <FormControlLabel
                        key={option}
                        checked={value.includes(option)}
                        disabled={props.disabled}
                        control={
                            <Checkbox
                                checked={value.includes(option)}
                                name={option}
                                onChange={() => handleChange(option)}
                            />
                        }
                        label={option}
                    />
                ))}
            </FormGroup>
            <FormHelperText>
                <Stack direction="row" justifyContent="flex-start">
                    <Typography>{touched && error ? error.value : ""}</Typography>
                </Stack>
            </FormHelperText>
        </FormControl>
    );
};

export default MultiSelectQuestion;
