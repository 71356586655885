import * as Yup from "yup";

import { FileUpload, QuestionType } from "../../generated/graphql";
import { Question, Section } from "../../globals/types";

/**
 * Convert a text question to a Yup schema
 */
const convertToYupTextValidation = (question: Question) => {
    let entry = Yup.string();
    if (question.maxChars) entry = entry.max(question.maxChars);
    return entry;
};

/**
 * Convert a date question to a Yup schema
 */
const convertToYupDateValidation = () => {
    return Yup.date();
};

/**
 * Convert a number question to a Yup schema
 */
const convertToYupNumberValidation = (question: Question) => {
    const { minNum, maxNum } = question;
    let entry = Yup.number();
    if (minNum) entry = entry.min(minNum);
    if (maxNum) entry = entry.max(maxNum);
    return entry;
};

const convertToYupSingleSelectValidation = () => {
    return Yup.string();
};

const convertToYupMultiSelectValidation = (question: Question) => {
    let entry = Yup.array().of(Yup.string());
    if (question.required) entry = entry.min(1);
    return entry;
};

const convertToYupBooleanValidation = () => {
    return Yup.string(); // boolean encoded as "0"/"1"
};

/**
 * Convert a rating question to a Yup schema
 */
const convertToYupRatingValidation = (question: Question) => {
    let entry = Yup.number();
    if (question.maxRating) entry = entry.max(question.maxRating);
    return entry;
};

/**
 * Convert a question to a Yup schema type
 */
const convertToYupTypeValidation = (question: Question) => {
    switch (question.type) {
        case QuestionType.Text:
            return convertToYupTextValidation(question);
        case QuestionType.Date:
            return convertToYupDateValidation();
        case QuestionType.Number:
            return convertToYupNumberValidation(question);
        case QuestionType.Rating:
            return convertToYupRatingValidation(question);
        case QuestionType.Boolean:
            return convertToYupBooleanValidation();
        case QuestionType.SingleSelect:
            return convertToYupSingleSelectValidation();
        case QuestionType.MultiSelect:
            return convertToYupMultiSelectValidation(question);
        default:
            return Yup.string();
    }
};

/**
 * Convert a question to a valid Yup schema.
 * There are two fields to an answer:
 * - value: the value of the answer, e.g. a string or string[]
 * - fileNames: the attached file names, always a string[]
 *
 * Here we validate that the value based on the type of question,
 * and the fileNames depending on if the question has required file uploads or not.
 */
const convertToYupValidation = (question: Question) => {
    let schema = convertToYupTypeValidation(question).label(question.title);
    if (question.required) schema = schema.required();
    switch (question.fileUpload) {
        case FileUpload.Required:
            return Yup.object().shape({
                value: schema,
                fileNames: Yup.array().of(Yup.string()).min(1).ensure(),
            });
        case FileUpload.Optional:
            return Yup.object().shape({
                value: schema,
                fileNames: Yup.array().of(Yup.string()).ensure(),
            });
        case FileUpload.Disabled:
            return Yup.object().shape({
                value: schema,
                fileNames: Yup.array(Yup.string()).ensure().max(0),
            });
    }
};

/**
 * Map a question to a Yup schema
 */
const validationMapper = (question: Question): [string, ReturnType<typeof convertToYupValidation>] => [
    question.id.toString(),
    convertToYupValidation(question),
];

/**
 * Map an array of sections to a Yup validation schema for all questions in all sections
 */
export const createValidationSchema = (sections: Section[] | undefined) => {
    return sections
        ? Yup.object(
              Object.fromEntries(
                  sections.reduce<[string, ReturnType<typeof convertToYupValidation>][]>(
                      (prev, curr) => [...prev, ...curr.questions.map((question) => validationMapper(question))],
                      []
                  )
              )
          )
        : Yup.object({});
};

export type AnswersSchema = ReturnType<typeof createValidationSchema>;
