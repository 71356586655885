import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { sectionIndexVar } from "../lib/apollo";

/**
 * Hook for retriving the section index and setting the section index from the URL query params,
 * keeping the URL query params up to date.
 */
const useSectionIndex = (): [number, React.Dispatch<React.SetStateAction<number>>] => {
    // useURLQuery may return stale values, but this is not visible in strict mode, so may fail in prod but not locally.
    // This will be fixed once we upgrade to react-router-dom 6.
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get("section");
    const token = searchParams.get("token")!;
    // Section URL param is 1-indexed. Set as undefined if not a valid number.
    let sectionURLParam: number | undefined;
    if (section) {
        const parsed = parseInt(section, 10);
        if (!Number.isNaN(parsed) || parsed >= 1) {
            sectionURLParam = parsed;
        }
    }

    const sectionIndex = useReactiveVar(sectionIndexVar);

    if (Number.isNaN(sectionIndex) && sectionURLParam !== undefined) {
        sectionIndexVar(sectionURLParam - 1);
    } else if (sectionURLParam === undefined) {
        sectionIndexVar(0);
        setSearchParams({ token, section: "1" }, { replace: true });
    } else if (sectionIndex !== sectionURLParam - 1) {
        setSearchParams({ token, section: (sectionIndex + 1).toString() }, { replace: true });
    }

    const setSectionIndex: React.Dispatch<React.SetStateAction<number>> = (value) => {
        if (typeof value === "function") {
            sectionIndexVar(value(sectionIndex));
        } else {
            sectionIndexVar(value);
        }
        window.scrollTo(0, 0);
    };

    return [sectionIndex, setSectionIndex];
};

export default useSectionIndex;
