import { FormControl, FormHelperText, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import React from "react";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertValue = (dateTime: any) => {
    const value = dateTime instanceof Date ? dateTime : dateTime.toDate();
    return value;
};

const DateQuestion: React.FC<QuestionProps> = ({ question, saveDraft, ...props }) => {
    const { values, getFieldMeta, errors, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[question.id];

    const { touched } = getFieldMeta(`${question.id}.value`);

    return (
        <FormControl error={touched && Boolean(error)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    key="questionnaire-date-picker"
                    format="DD-MM-YYYY"
                    value={values[question.id].value ? dayjs(values[question.id]?.value as string) : null}
                    disabled={props.disabled}
                    onChange={(newValue) => {
                        const changedValue = newValue ? convertValue(newValue) : undefined;
                        if (Number.isNaN(changedValue.getTime())) return;
                        setFieldValue(question.id, {
                            value: changedValue ? dayjs(changedValue) : null,
                            fileNames: values[question.id].fileNames,
                        });
                        saveDraft([changedValue]);
                    }}
                />
            </LocalizationProvider>
            <FormHelperText>
                <Stack direction="row" justifyContent="flex-start">
                    <Typography>{touched && error ? error.value : ""}</Typography>
                </Stack>
            </FormHelperText>
        </FormControl>
    );
};

export default DateQuestion;
