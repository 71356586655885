import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { basePath } from "../../../containers/ApplicationPage/constants";
import useToken from "../../../hooks/useToken";
import img from "../submitted.svg";

interface Props {
    open: boolean;
    type: "internal" | "external";
}

export const SubmittedModal: React.FC<Props> = ({ open, type }) => {
    const { token } = useToken();
    const navigate = useNavigate();

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            PaperProps={{
                sx: {
                    paddingTop: 3,
                },
            }}
        >
            <DialogContent sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Grid container direction="column" alignItems="center" rowSpacing={2} paddingX={2}>
                    <Grid item>
                        <img src={img} alt="" width="100%" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" fontSize={18}>
                            <FormattedMessage defaultMessage="Thank you for submitting." />
                        </Typography>
                    </Grid>
                    {type === "external" && (
                        <Grid item paddingBottom={3}>
                            <Typography variant="body1" fontSize={16}>
                                <FormattedMessage defaultMessage="You may now close this window." />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center" }}>
                <Grid item paddingBottom={3}>
                    <Button
                        color="primary"
                        onClick={() => {
                            navigate(`${basePath}/?token=${token}`);
                        }}
                    >
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
