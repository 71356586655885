import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Question } from "../../../generated/graphql";
import { Questionnaire } from "../../../globals/types";
import useSectionIndex from "../../../hooks/useSectionIndex";
import { initialValue } from "../../Form/helpers";

type Props = {
    errorSubmitting: boolean;
    missingAnswers: Question[];
    closeModal: () => void;
    questionnaire: Questionnaire;
};

/**
 * Error Dialog shown when there are errors in the form after attempting to submit
 * Displays the sections and questions that have errors.
 */
const ErrorDialog: React.FC<Props> = ({ errorSubmitting, missingAnswers, closeModal, questionnaire }) => {
    const { errors, isValid, submitCount } = useFormikContext<Record<string, initialValue>>();
    const [, setSectionIndex] = useSectionIndex();
    const [prevSubmitCount, setPrevSubmitCount] = useState<number>(submitCount);

    /**
     * If `submitCount` > 0, then the form has been submitted at least once.
     * If form is invalid, then there are errors in the form. If the
     * `submitCount` is greater than `prevSubmitCount`, then we have attempted
     * to submit another time, and the form is still not valid. Thus,
     * we should open the dialog.
     */
    const open = errorSubmitting || (prevSubmitCount < submitCount && !isValid);

    if (open) {
        const sectionsWithErrors = questionnaire.sections.filter((section) =>
            section.questions.some(
                (question) =>
                    typeof errors[question.id] !== "undefined" ||
                    missingAnswers.some((missingAnswer) => missingAnswer.id === question.id)
            )
        );

        const onClose = () => {
            setPrevSubmitCount(submitCount);
            closeModal();
        };

        return (
            <Dialog open={open} onClose={() => onClose()}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <FormattedMessage defaultMessage="Missing answers" />
                        <IconButton onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {errorSubmitting && missingAnswers?.length == 0 ? (
                        <Stack spacing={1}>
                            <Typography variant="subtitle1" color="red">
                                <FormattedMessage defaultMessage="There was an error submitting your answers. Please try again." />
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack spacing={2}>
                            {sectionsWithErrors.map((section, index, array) => (
                                <Stack spacing={1} key={section.id}>
                                    <Stack>
                                        <Typography variant="h6">{section.name}</Typography>
                                    </Stack>
                                    <Stack spacing={1}>
                                        {section.questions.map((question, qIndex) =>
                                            errors[question.id] ? (
                                                <Stack key={question.id} padding={1} spacing={0.3}>
                                                    <Typography variant="subtitle1">{`${qIndex + 1}. ${
                                                        question.title
                                                    }`}</Typography>
                                                    <Stack paddingX={1} spacing={0.3}>
                                                        {errors[question.id]?.value && (
                                                            <Typography variant="subtitle2" color="red">
                                                                {errors[question.id]?.value}
                                                            </Typography>
                                                        )}
                                                        {errors[question.id]?.fileNames && (
                                                            <Typography variant="subtitle2" color="red">
                                                                {errors[question.id]?.fileNames}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            ) : missingAnswers.some(
                                                  (missingAnswer) => missingAnswer.id === question.id
                                              ) ? (
                                                <Stack key={question.id} padding={1} spacing={0.3}>
                                                    <Typography variant="subtitle1">{`${qIndex + 1}. ${
                                                        question.title
                                                    }`}</Typography>
                                                    <Stack paddingX={1} spacing={0.3}>
                                                        <Typography variant="subtitle2" color="red">
                                                            <FormattedMessage defaultMessage="Error uploading answer" />
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ) : null
                                        )}
                                        <Stack direction="row" justifyContent="flex-end">
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setSectionIndex(
                                                        questionnaire.sections.findIndex(
                                                            (currSection) => currSection.id === section.id
                                                        )
                                                    );
                                                    onClose();
                                                }}
                                            >
                                                <FormattedMessage defaultMessage="Go to section" />
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    {index < array.length - 1 && <Divider />}
                                </Stack>
                            ))}
                        </Stack>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
    return null;
};

export default ErrorDialog;
