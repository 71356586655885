import { CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import React from "react";

import { Answer, Section as TSection } from "../../../globals/types";
import Question from "../../Question";

type Props = {
    section: TSection;
    preview?: boolean;
    answers: Record<string, Answer>;
    disabled: boolean;
    setSubmitBlocked: React.Dispatch<React.SetStateAction<boolean>>;
};

const Section: React.FC<Props> = ({ section, preview, answers, disabled, setSubmitBlocked }) => {
    return (
        <>
            <CardHeader title={<Typography variant="h6">{section.name}</Typography>} />
            <CardContent sx={{ padding: 2 }}>
                <Stack spacing={2}>
                    {section.questions.map((question, index) => (
                        <div key={question.id}>
                            <Question
                                question={question}
                                number={index + 1}
                                preview={preview}
                                answer={answers[question.id]}
                                disabled={disabled}
                                setSubmitBlocked={setSubmitBlocked}
                            />
                            {index < section.questions.length - 1 && <Divider sx={{ marginY: 1 }} />}
                        </div>
                    ))}
                </Stack>
            </CardContent>
        </>
    );
};

export default Section;
