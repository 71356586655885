import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";

import { DropZoneArea } from "./style";

interface DropZoneProps {
    children: React.ReactNode;
    disabled?: boolean;
}

const DropZone = ({ children, disabled }: DropZoneProps) => {
    const { formatMessage } = useIntl();
    const [uploading, setUploading] = React.useState(false);

    const dragEnter = (e: React.MouseEvent) => {
        e.preventDefault();
        !disabled && setUploading(true);
    };

    const dragLeave = (e: React.MouseEvent) => {
        e.preventDefault();
        setUploading(false);
    };

    const drop = () => {
        setUploading(false);
    };

    return (
        <DropZoneArea
            uploading={uploading ? true : undefined}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={drop}
            disabled={disabled ?? false}
        >
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                <Typography variant="subtitle1">{formatMessage({ defaultMessage: "Drop files" })}</Typography>
                <Grid justifyContent="center">
                    <Typography display="inline" variant="body2" sx={{ pr: 0.5 }}>
                        {formatMessage({ defaultMessage: "or" })}
                    </Typography>
                    <Typography display="inline" variant="subtitle2" color="primary" sx={{ pr: 0.5 }}>
                        {formatMessage({ defaultMessage: "browse" })}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {formatMessage({ defaultMessage: "to choose a file" })}
                    </Typography>
                </Grid>
                <Typography variant="subtitle2" color="textSecondary">
                    {formatMessage({ defaultMessage: "Max file size: 20MB" })}
                </Typography>
            </Grid>
            {children}
        </DropZoneArea>
    );
};

export default DropZone;
