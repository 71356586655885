import { ApolloQueryResult } from "@apollo/client";
import { InfoSquare } from "@ignite-analytics/icons";
import { AppBar, Button, Stack, Toolbar, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { GetAssessmentQuery } from "../../../generated/graphql";
import useSectionIndex from "../../../hooks/useSectionIndex";

type Props = {
    sectionCount: number;
    disabled?: boolean;
    refetch: () => Promise<ApolloQueryResult<GetAssessmentQuery>>;
};

const Footer: React.FC<Props> = ({ sectionCount, disabled, refetch }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sectionIndex, setSectionIndex] = useSectionIndex();
    const [showAppBar, setShowAppBar] = useState(false);

    // handle scroll to show/hide footer on small screen
    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.scrollY;

        if (scrollPosition + windowHeight < documentHeight - 100) {
            setShowAppBar(false);
        } else {
            setShowAppBar(true);
        }
    };

    // useEffect to initialize the footer showing on initial load
    // have dependency on sectionIndex to reset scroll position when section changes
    useEffect(() => {
        // have to call handleScroll() here to show footer on initial load if scroll position is at the bottom
        // otherwise footer will not show on initial load for sections without scroll
        handleScroll();
    }, [sectionIndex]);

    // useEffect to handle scroll
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // useEffect to handle window resize to change view mode
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const smallScreen = windowWidth < 900;

    const firstSection = sectionIndex === 0;
    const lastSection = sectionIndex + 1 === sectionCount;

    const { submitForm } = useFormikContext();

    async function handleSubmit() {
        // Refetch to make sure another user or window hasn't invalidated any form
        // answers. Attachments are extra vulnerable as we merely save the filename
        // without knowing the file's real state in storage bucket at submission.
        await refetch();
        submitForm();
    }
    function handleNext() {
        setSectionIndex((prevSection) => prevSection + 1);
    }

    function handlePrevious() {
        setSectionIndex((prevSection) => prevSection - 1);
    }

    function normalFooter() {
        return (
            <AppBar position="fixed" sx={{ backgroundColor: "white", top: "auto", bottom: 0 }}>
                <Toolbar sx={{ justifyContent: "space-evenly", alignItems: "center" }}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <InfoSquare sx={{ color: "black" }} />
                        <Typography variant="body2" color="black" component="div">
                            <FormattedMessage defaultMessage="Questions will be saved automatically when you make changes. Your answer will be sent once you click the submit button. * - Required" />
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {!firstSection && (
                            <Button type="button" onClick={() => handlePrevious()}>
                                <FormattedMessage defaultMessage="Back" />
                            </Button>
                        )}
                        {lastSection ? (
                            <Stack>
                                <Button disabled={disabled} onClick={handleSubmit}>
                                    <FormattedMessage defaultMessage="Submit" />
                                </Button>
                            </Stack>
                        ) : (
                            <Button type="button" onClick={() => handleNext()}>
                                <FormattedMessage defaultMessage="Next" />{" "}
                            </Button>
                        )}
                    </Stack>
                </Toolbar>
            </AppBar>
        );
    }

    function smallScreenFooter() {
        return (
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "white",
                    height: 64,
                    top: "auto",
                    bottom: 0,
                    visibility: showAppBar ? "visible" : "hidden",
                }}
            >
                <Toolbar sx={{ justifyContent: "flex-end" }}>
                    <Stack width="100%">
                        {lastSection ? (
                            <Stack>
                                <Button fullWidth disabled={disabled} onClick={handleSubmit}>
                                    <FormattedMessage defaultMessage="Submit" />
                                </Button>
                            </Stack>
                        ) : (
                            <Button type="button" fullWidth onClick={() => handleNext()}>
                                <FormattedMessage defaultMessage="Next" />{" "}
                            </Button>
                        )}
                    </Stack>
                </Toolbar>
            </AppBar>
        );
    }

    return smallScreen ? smallScreenFooter() : normalFooter();
};
export default Footer;
