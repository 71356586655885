import React from "react";

import { User } from "../types";

export interface UserContext {
    user?: User;
    setUser: (user: User) => void;
}

export const userContext = React.createContext<UserContext>({
    user: undefined,
    setUser: () => undefined,
});
