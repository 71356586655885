import { debounce, FormControl, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { initialValue } from "../../Form/helpers";
import { QuestionProps } from "../interfaces";

const TextQuestion: React.FC<QuestionProps> = ({ saveDraft, ...props }) => {
    const debounceFunc = useMemo(() => {
        return debounce((val) => saveDraft([val]), 1000);
    }, [saveDraft]);

    const { formatMessage } = useIntl();
    const { values, errors, getFieldMeta, setFieldValue } = useFormikContext<Record<string, initialValue>>();
    const error = errors[props.question.id];

    const { touched } = getFieldMeta(`${props.question.id}.value`);

    const maxCharacters = props.question.maxChars;
    const counterText = `${values[props.question.id].value.length}/${maxCharacters}`;

    return (
        <FormControl sx={{ width: "100%" }} error={touched && Boolean(error)}>
            <TextField
                {...props}
                name={props.question.id}
                fullWidth
                multiline
                minRows={3}
                error={touched && Boolean(error)}
                value={values[props.question.id].value}
                inputProps={{ maxLength: maxCharacters }}
                onChange={(event) => {
                    setFieldValue(props.question.id, {
                        value: event.target.value,
                        fileNames: values[props.question.id].fileNames,
                    });
                    debounceFunc(event.target.value);
                }}
                placeholder={formatMessage({ defaultMessage: "Write here..." })}
                helperText={
                    <Stack direction="row" justifyContent="space-between">
                        <Typography>{touched && error ? error.value : ""}</Typography>
                        <Typography>{counterText}</Typography>
                    </Stack>
                }
            />
        </FormControl>
    );
};

export default TextQuestion;
